import softwaredesign1 from '../images/Software-Design-1.jpg'
import softwaredesign2 from '../images/Software-Design-2.jpg'
import softwaredesign3 from '../images/Software-Design-3.jpg'
import softwaredesign4 from '../images/Software-Design-4.jpg'
import softwaredesign5 from '../images/Software-Design-5.jpg'
import AboutButton from './Aboutbutton'
import ContactButton from './Contactbutton'

export default function HomeContent() {
    return (
      <div className="container-flex" id='topDiv'>
        <div class="row" id="topRow">
          <div class="col-md-4 offset-md-2">
            <img src={softwaredesign1} alt="Software Design" />
            <h2>Our Services</h2>
            <p>We offer a wide range of software design services to meet your needs. From custom software development for 
              web and mobile to cloud solutions with Artificial Intelligence, we've got you covered.</p>
            <div class="text-center">
              <AboutButton />
            </div>            
          </div>
          <div class="col-md-4">
            <img src={softwaredesign2} alt="Software Design" />
            <h2>Our Solutions</h2>
            <p>Our software solutions are designed to help you achieve your business goals. From e-commerce to mobile apps to custom solutions that run 
              entirely in the cloud, we've got the tools you need.</p>
            <div className="text-center">              
              <ContactButton />
            </div>            
          </div>       
        </div>
        <div class="row" id='middleRow'>
          <div class="col-md-4">
            <img src={softwaredesign3} alt="Software Design" />
            <h2>Custom Software Development</h2>
            <p>Our team of experts can help you design and develop custom software solutions that meet your unique business needs.</p>
            <div className="text-center">
              <AboutButton />
            </div>            
          </div>
          <div class="col-md-4">
            <img src={softwaredesign4} alt="Software Design" />
            <h2>Mobile Development</h2>
            <p>We do mobile apps for iOS and Android. Our team has experience with a wide range of mobile app development frameworks and technologies.</p>
            <div className="text-center">              
              <AboutButton />
            </div>            
          </div>
          <div class="col-md-4">
            <img src={softwaredesign5} alt="Software Design" />
            <h2>Web App Development</h2>
            <p>We design web applications that are fast, secure, scalable, and responsive. Let our team help you with your own website!</p>
            <div className="text-center">              
              <AboutButton />
            </div>            
          </div>     
        </div>
      </div>           
    );
  }