import { Link, useMatch, useResolvedPath } from "react-router-dom"
import logo from '../images/msjclogo.png';

import React, { useState } from 'react';
import { Navbar} from 'react-bootstrap';

export default function TopNavbar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expanded={expanded} expand="lg" bg="dark" variant="dark" className="nav">
      <Link to="/">
        <img src={logo} class="companyimage d-inline-block align-top" alt="MSJC Software"/>
        <span class="companyname">MSJC Software</span>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{paddingRight: '30px'}}>
        <ul>        
          <CustomLink to="/about">About</CustomLink>
          <CustomLink to="/contact">Contact</CustomLink>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
