import Contact from "../pages/Contact"
import Home from "../pages/Home"
import About from "../pages/About"
import { Route, Routes } from "react-router-dom"

export default function AppRouter() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
        </Routes>
    )
}