import { useNavigate } from 'react-router-dom';

export default function AboutButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/about');
  };

  return <button type="button" class="btn btn-dark" onClick={handleClick}>Learn more</button>
}