import { useNavigate } from 'react-router-dom';

export default function ContactButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };

  return <button type="button" class="btn btn-dark" onClick={handleClick}>Contact us</button>
}