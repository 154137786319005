import React, { useState } from 'react';

export default function ContactForm() {
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [MessageInput, setMessageInput] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Name: ${nameInput}, Email: ${emailInput}`);
  };

  return (
    <div className="container-flex offset-md-3" id='contactDiv'>
        <p>
            Thank you for your interest in MSJC Software. Please fill out the form below to contact us. We will get back to you as soon as possible. 
        </p>
        <form onSubmit={handleSubmit}>
            <div class="form-group">
                <label for="nameInput">
                    Your name:
                    <input type="text" value={nameInput} onChange={e => setNameInput(e.target.value)} class="form-control form-control-lg" placeholder="Enter your name" />
                </label>            
            </div>
            <div class="form-group">
                <label for="emailInput" >
                    Your email address:
                    <input type="email" value={emailInput} onChange={e => setEmailInput(e.target.value)} class="form-control form-control-lg" placeholder="Enter your email address" />
                </label>
            </div> 
            <div class="form-group">
                <label>
                    Your message:
                    <textarea type="text" value={MessageInput} onChange={e => setMessageInput(e.target.value)} class="form-control form-control-lg" placeholder="Enter your message" />
                </label>
            </div> 
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </div>
  );
}