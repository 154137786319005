import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
    return (
        <footer className="footer bg-dark">
            <Container>
                <Row>
                    <Col className="text-center" style={{color: 'white'}}>
                        <p>&copy;2024 MSJC Software. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;