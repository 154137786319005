import softwaredesign6 from '../images/Software-Design-6.jpg'
import softwaredesign7 from '../images/Software-Design-7.jpg'
import softwaredesign8 from '../images/Software-Design-8.jpg'

export default function AboutContent() {
    return (
        <div className="container-flex" id='aboutTopDiv'>
            <div class="row" id="aboutTopRow">
                <div class="col-md-4 offset-md-2">
                    <img src={softwaredesign6} alt="Software Design" />
                </div>
                <div class="col-md-4 aboutText">
                    <h2>Our Mission</h2>
                    <p>Since 2010, MSJC Software has been helping small businesses expand their marketplace by developing innovative apps and websites 
                        that maximize the business visibility to potential customers while increasing the business' return on investment. Our strength 
                        is in offering state-of-the-art designs, e-commerce features, website communication tools, and administration resources.
                        Our staff delivers their best work at any project and we guarantee our work with constant support to all of our customers.</p>
                </div>
            </div>
            <div class="row" id="aboutMiddleRow">
                <div class="col-md-4 offset-md-2 aboutText">
                    <h2>Our Team</h2>
                    <p>Meet the talented individuals who make up our team:</p>
                    <h6>Oscar Peña Castro, Solutions Architect</h6>
                </div>
                <div class="col-md-4">
                    <img src={softwaredesign7} alt="Software Design" />
                </div>                
            </div>
            <div class="row" id="aboutBottomRow">
                <div class="col-md-4 offset-md-2">
                    <img src={softwaredesign8} alt="Software Design" />
                </div>
                <div class="col-md-4 aboutText">
                    <h2>Our Services</h2>
                    <p>We offer a wide range of software development services, including:</p>
                    <ul>
                        <li>Custom software development</li>
                        <li>Mobile app development</li>
                        <li>Web development</li>
                        <li>Cloud architecture</li>
                        <li>Software consulting</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}