import Approuter from "./components/Approuter"
import TopNavbar from "./components/Navbar"
import Footer from "./components/footer"

function App() {
  return (
    <>
      <TopNavbar />
      <div className="container-flex">
        <Approuter />                       
      </div>  
      <Footer />         
    </>
  )
}

export default App